const navMenu = () => {
  const navToggle = document.querySelector('.header__link--categories');
  const navMenu = document.querySelector('.navigation');
  const navOverlay = document.querySelector('.navigation__overlay');
  navToggle.addEventListener('click', () => {
    // document.body.classList.toggle('fixed');
    navOverlay.classList.toggle('is-open');
    navMenu.classList.toggle('is-open');
    navToggle.classList.toggle('is-open');
  });
  navOverlay.addEventListener('click', () => {
    navOverlay.classList.toggle('is-open');
    navMenu.classList.toggle('is-open');
    navToggle.classList.toggle('is-open');
    // document.body.classList.toggle('fixed');
  });
};

const mobileMenu = () => {
  const mobileToggle = document.querySelector('.header__toggle');
  const mobileMenu = document.querySelector('.mobile-menu');
  mobileToggle.addEventListener('click', () => {
    document.body.classList.toggle('fixed');
    mobileMenu.classList.toggle('is-open');
    mobileToggle.classList.toggle('is-open');
  });
};

export { navMenu, mobileMenu };
