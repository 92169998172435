export const FAQDropdown = () => {
  const questions = [...document.querySelectorAll('.faq-block__item')];

  if (questions.length == 0) return;

  questions.forEach((question) => {
    question.addEventListener('click', (e) => {
      if (e.target.classList.contains('is-open')) {
        e.target.classList.remove('is-open');
        return;
      }
      e.target.classList.add('is-open');
    });
  });
};
