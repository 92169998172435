export const donate = () => {
  const form = document.querySelector('.donate form');
  const matrix = [
    {
      interval: 'one-time',
      amount: '10',
      link: 'https://buy.stripe.com/cN2aH28EF7pX6oobIR',
    },
    {
      interval: 'one-time',
      amount: '25',
      link: 'https://buy.stripe.com/cN23eAf33eSp5kkbIS',
    },
    {
      interval: 'one-time',
      amount: '50',
      link: 'https://buy.stripe.com/dR63eA6wx25D14428j',
    },
    {
      interval: 'one-time',
      amount: '100',
      link: 'https://buy.stripe.com/8wM7uQ9IJ7pX7ss00c',
    },
    {
      interval: 'monthly',
      amount: '10',
      link: 'https://buy.stripe.com/dR6eXi4opeSpeUU6op',
    },
    {
      interval: 'monthly',
      amount: '25',
      link: 'https://buy.stripe.com/14kdTedYZh0xfYYeUW',
    },
    {
      interval: 'monthly',
      amount: '50',
      link: 'https://buy.stripe.com/fZe8yUcUVfWt7ss7sv',
    },
    {
      interval: 'monthly',
      amount: '100',
      link: 'https://buy.stripe.com/3cs8yU3kleSpfYY5ko',
    },
    {
      interval: 'yearly',
      amount: '10',
      link: 'https://buy.stripe.com/3cs4iE7AB4dLcMM4gl',
    },
    {
      interval: 'yearly',
      amount: '25',
      link: 'https://buy.stripe.com/8wM5mIf334dLeUU3ci',
    },
    {
      interval: 'yearly',
      amount: '50',
      link: 'https://buy.stripe.com/00g4iEg7739HcMMdQX',
    },
    {
      interval: 'yearly',
      amount: '100',
      link: 'https://buy.stripe.com/5kA2aw6wxdOl5kk7sA',
    },
  ];
  if (!form) return;
  form.addEventListener('input', function () {
    const chooseAmount = document.querySelector('[name=choose-amount]');
    const paymentLink = document.querySelector('form a.payment-link');
    const interval = document.querySelector('[name=interval]:checked');
    const amount = document.querySelector(['[name=amount]:checked']);
    const filter = {
      interval: interval.value,
      amount: amount.value,
    };

    // If free form has been filled in, go to free form donation
    if (chooseAmount.value.length > 0) {
      paymentLink.href = 'https://donate.stripe.com/28o8yU6wx8u16ooaEE';
      return;
    }

    const match = matrix.filter(function (item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] != filter[key]) return false;
      }
      return true;
    });

    if (match) paymentLink.href = match[0].link;
  });
};
