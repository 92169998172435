import Swiper from 'swiper';

import 'swiper/swiper-bundle.min.css';

export const slider = () => {
  const sliders = document.querySelectorAll('.slider');

  if (sliders.length == 0) return;

  let spv = 5.2;

  if (window.innerWidth < 768) {
    spv = 1.2;
  }
  if (window.innerWidth > 768) {
    spv = 3.2;
  }
  if (window.innerWidth > 1200) {
    spv = 5.2;
  }

  sliders.forEach((slider) => {
    new Swiper(slider, {
      slidesPerView: spv,
      spaceBetween: 24,
      mousewheel: false,
      preventClicks: true,
    });
  });
};
