import autoComplete from '@tarekraafat/autocomplete.js';

export const autoCompleteJs = () => {
  if (!document.getElementById('autoComplete')) return;

  new autoComplete({
    placeHolder: 'What can we help you with?',
    searchEngine: 'loose',
    data: {
      src: async () => {
        try {
          const res = await fetch('/wp-json/questions/get');
          const json = await res.json();
          return JSON.parse(json);
        } catch (error) {
          return error;
        }
      },
      keys: ['title'],
    },
    resultsList: {
      noResults: true,
    },
    resultItem: {
      element: (item, data) => {
        // Modify Results Item Style
        item.style = 'display: flex; justify-content: space-between;';
        // Modify Results Item Content
        item.innerHTML = `
        <a href="${data.value.link}">
          <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
            ${data.match}
          </span>
          <span style="padding:4px 8px;background:var(--${data.value.categoryColor});font-weight:300;font-size:0.875rem;color:white;border-radius:4px;">${data.value.categoryName}</span>
        </a>
        `;
      },
      highlight: true,
    },
  });
};
