'use strict';

jQuery.migrateMute = true;

import { slider } from './includes/slider';
import { FAQDropdown } from './includes/faq';
import { donate } from './includes/donate';
import { autoCompleteJs } from './includes/auto-complete';
import { navMenu, mobileMenu } from './includes/nav';

document.addEventListener('DOMContentLoaded', () => {
  slider();
  FAQDropdown();
  navMenu();
  mobileMenu();
  autoCompleteJs();
  donate();

  window.addEventListener('resize', () => {
    slider();
  });
});
